import React from "react";
import { Box } from "@mui/material";
import WhiteLogo from "shared/assets/WhiteLogo.svg";
import { useStyles } from "./Header.style";
import { Link } from "react-router-dom";

export const Logo: React.FC = () => {
  const classes = useStyles();
  return (
    <Box>
      <Link to="/">
        <img src={WhiteLogo} alt="logo" className={classes.logo} />
      </Link>
    </Box>
  );
};
