import {
  ClickAwayListener,
  Dialog,
  TextField,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Button,
  Backdrop,
  Box,
  CircularProgress,
} from "@mui/material";
import { useStore } from "effector-react";
import { editRaceModel } from "..";
import { useState, useEffect, SyntheticEvent } from "react";
import { DISTANCES, DistancesString, IRace } from "shared/API/types";
import { distancesNames } from "entities/race";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { dateToRequestFormat, dateToShowFormat } from "shared/helpers/dates";
import { CountrySelect } from "shared/components/shared/CountrySelect/CountrySelect";
import { CountryType } from "shared/components/shared/CountrySelect/countries";

interface LoadingViewProps {
  open: boolean;
}

export const LoadingView: React.FC<LoadingViewProps> = ({ open }) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CircularProgress color="inherit" />
        {/* You can add a message below the loader if needed */}
        {/* <Typography variant="body2" color="inherit">Loading...</Typography> */}
      </Box>
    </Backdrop>
  );
};

export const EditRaceModal = () => {
  const race = useStore(editRaceModel.$raceToEdit);

  const isOpen = useStore(editRaceModel.$isOpen);
  const [raceName, setRaceName] = useState(race?.name);
  const [distances, setDistances] = useState(race?.distances || []);
  const [city, setCity] = useState(race?.city);
  const [country, setCountry] = useState(race?.country);
  const [resultsLink, setResultsLink] = useState(race?.resultsLink);
  const [date, setDate] = useState(
    dayjs(race?.date || new Date()).format(dateToRequestFormat)
  );

  const isRequiredFieldsFilled =
    raceName?.length !== 0 &&
    date.length !== 0 &&
    country?.length !== 0 &&
    city?.length !== 0;

  useEffect(() => {
    if (race) {
      setRaceName(race.name || "");
      setDistances(race.distances || []);
      setCity(race.city || "");
      setCountry(race.country || "");
      setResultsLink(race.resultsLink || "");
      setDate(
        dayjs(race.date.split(".").reverse().join("/")).format(
          dateToRequestFormat
        )
      );
    }
  }, [race]); // Run this effect whenever race changes

  const handleClose = () => {
    editRaceModel.modalVisibilityChanged(false);
    editRaceModel.raceEditReset();
    // Reset form fields on close
    setRaceName("");
    setDistances([]);
    setCity("");
    setCountry("");
    setResultsLink("");
  };

  const handleSubmit = () => {
    // Here you can handle form submission, e.g., send data to backend

    if (race)
      editRaceModel.editRaceSubmitted({
        name: raceName,
        distances,
        city,
        country,
        resultsLink,
        id: race.id,
        date,
      });
    handleClose();
  };

  const handleCountrySelection = (
    event: SyntheticEvent,
    value: CountryType | null,
    reason: string
  ) => {
    if (value) setCountry(value);
  };

  const handleDistancesCheckboxChange = (distance: DistancesString) => {
    if (distances.includes(distance)) {
      setDistances(distances.filter((d) => d !== distance));
    } else {
      setDistances([...distances, distance]);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={() => editRaceModel.modalVisibilityChanged(false)}
    >
      <DialogTitle>{race?.name || ""}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Race Name"
          fullWidth
          value={raceName}
          onChange={(e) => setRaceName(e.target.value)}
        />

        <DesktopDatePicker
          label="choose race date"
          value={dayjs(date.split(".").reverse().join("/"))}
          disableMaskedInput
          onChange={(newValue: Dayjs | null) => {
            if (newValue) setDate(newValue.format(dateToRequestFormat));
          }}
          inputFormat={dateToShowFormat}
          renderInput={(params) => (
            <TextField
              margin="dense"
              {...params}
              required
              fullWidth
              helperText={"DD.MM.YYYY"}
            />
          )}
        />
        <CountrySelect
          margin="dense"
          value={country || ""}
          handleFormChange={handleCountrySelection}
        />

        <TextField
          margin="dense"
          label="City of Race"
          fullWidth
          value={city}
          onChange={(e) => setCity(e.target.value)}
        />

        <TextField
          margin="dense"
          label="Links to Results"
          fullWidth
          value={resultsLink}
          onChange={(e) => setResultsLink(e.target.value)}
        />
        <FormGroup
          row
          sx={{
            marginTop: "10px",
          }}
        >
          {distancesNames.map((distanceName) => (
            <FormControlLabel
              key={distanceName}
              name="distances"
              control={
                <Checkbox
                  onChange={() => handleDistancesCheckboxChange(distanceName)}
                  checked={distances?.includes(distanceName)}
                />
              }
              label={DISTANCES[distanceName]}
              labelPlacement="bottom"
              value={distanceName}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit} disabled={!isRequiredFieldsFilled}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
