import React, { useEffect, useState, useContext } from "react";
import {
  TableContainer,
  Paper,
  Table,
  Container,
  Box,
  Typography,
  Button,
} from "@mui/material";
import { RaceTableBody } from "./RaceTableBody";
import { RaceTableHead } from "./RaceTableHead";
import { fetchAllRaces } from "../../../shared/API/apiUtilities";
import { IRace } from "../../../shared/API/types";
import { useStyles } from "./RaceTable.styles";
import { UserContext } from "../../../providers/UserProvider";
import { Link, Redirect } from "react-router-dom";
import { useGate, useStore } from "effector-react";
import { racePageModel } from "..";
import { AddUserModal } from "./AddUserModal";
import { EditRaceModal } from "features/edit-race";

export const RaceTable: React.FC = () => {
  useGate(racePageModel.Gate);

  const { currentUser } = useContext(UserContext);
  const races = useStore(racePageModel.$races);

  const isUserAdmin = currentUser?.roles.includes("ADMIN") || false;
  const isUserCoach = currentUser?.roles.includes("COACH") || false;

  const classes = useStyles();

  if (currentUser?.roles.includes("USER")) {
    return <Redirect to="/error_404" />;
  }

  return (
    <>
      <Box className={classes.background}>
        <Container className={classes.page}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="h1"
              align="left"
              className={classes.tableLabel}
            >
              All Races
            </Typography>

            {(isUserAdmin || isUserCoach) && (
              <Button component={Link} to="/add-race" variant="contained">
                Add Race
              </Button>
            )}
          </Box>
          <TableContainer component={Paper} className={classes.table}>
            <Table>
              <RaceTableHead />
              <RaceTableBody
                races={races}
                isAdmin={isUserAdmin}
                isCoach={isUserCoach}
              />
            </Table>
          </TableContainer>
        </Container>
      </Box>
      <AddUserModal />
      <EditRaceModal />
    </>
  );
};
