import React, { useContext, useEffect } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { useStyles } from "./Header.style";
import { Logo } from "./Logo";
import { AuthButton } from "shared/components/auth/AuthButton";
import { AuthContext } from "../../providers/AuthProvider";
import { AccountMenu } from "./AccountMenu";
import { UserContext } from "../../providers/UserProvider";

import { StyledCircularProgress } from "shared/components/shared/StyledCircularProgress";
import { viewerModel } from "entities/viewer";
import { useStore } from "effector-react";
import { $getViewerError } from "./model";
import { getCurrentUser } from "shared/API/api";

export const Header: React.FC = () => {
  const isLoading = useStore(viewerModel.getViewerFx.pending);
  const error = useStore($getViewerError);

  const classes = useStyles();
  const { authenticated } = useContext(AuthContext);
  const { currentUser, setUser } = useContext(UserContext);
  const viewer = useStore(viewerModel.$viewer);

  useEffect(() => {
    if (authenticated && !currentUser) {
      viewerModel.getViewerFx();
      // getCurrentUser().then((response) => setUser(response.data));
    }
  }, [authenticated, currentUser, setUser]);

  useEffect(() => {
    if (viewer) {
      setUser(viewer);
    }
  }, [viewer]);

  // if (isLoading) return <StyledCircularProgress />;
  if (error) return <div>{error}</div>;

  return (
    <AppBar position="fixed">
      <Toolbar className={classes.header}>
        <Logo />
        {authenticated ? (
          <AccountMenu
            avatar={currentUser?.profile}
            role={currentUser?.roles}
          />
        ) : (
          <AuthButton />
        )}
      </Toolbar>
    </AppBar>
  );
};
