import { RaceTable } from "pages/RaceTable";
import { lazy } from "react";
import { IRouteConfig } from "./types";

// const StartPage = lazy(() => import('../views/startPage'));
const AdminTable = lazy(() => import("../AdminTable/AdminTable"));
const UserProfile = lazy(() => import("../UserProfile/UserProfile"));
const AuthRedirect = lazy(() => import("../AuthRedirect/AuthRedirect"));
const ErrorPage = lazy(() => import("../ErrorPage/ErrorPage"));
const CoachTemplates = lazy(() => import("../CoachTemplates/CoachTemplates"));
const AddRunner = lazy(() => import("../AddUserPage/AddUserPage"));

const AddRace = lazy(() => import("../AddRacePage/AddRacePage"));

export const ROUTER_CONFIG: IRouteConfig[] = [
  {
    path: "/list",
    key: "ADMIN_TABLE",
    component: AdminTable,
    exact: true,
    private: true,
  },
  {
    path: "/profile",
    key: "USER_PROFILE",
    component: UserProfile,
    exact: true,
    private: true,
  },
  {
    path: "/profile/:userId",
    key: "USER_PROFILE",
    component: UserProfile,
    exact: true,
    private: true,
  },
  {
    path: "/redirect",
    key: "AUTH_REDIRECT",
    component: AuthRedirect,
    exact: true,
  },
  {
    path: "/error_404",
    key: "ERROR_PAGE",
    component: ErrorPage,
    exact: true,
  },
  {
    path: "/templates",
    key: "COACH_TEMPLATES",
    component: CoachTemplates,
    exact: true,
    private: true,
  },
  {
    path: "/race",
    key: "RACE_TABLE",
    component: RaceTable,
    exact: true,
    private: true,
  },
  {
    path: "/add-runner",
    key: "ADD_RUNNER",
    component: AddRunner,
    exact: true,
    private: true,
  },
  {
    path: "/add-race",
    key: "ADD_RACE",
    component: AddRace,
    exact: true,
    private: true,
  },
];
