import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  FormLabel,
  FormControl,
} from "@mui/material";
import { useGate, useStore } from "effector-react";
import { racePageModel } from "..";
import { DISTANCES, DistancesString } from "shared/API/types";

export const AddUserModal = () => {
  useGate(racePageModel.AddUserModalGate);
  const isOpen = useStore(racePageModel.$isAddUserModalActive);
  const activeRace = useStore(racePageModel.$choosedRace);
  const choosedStudent = useStore(racePageModel.$choosedUser);
  const choosedDistance = useStore(racePageModel.$choosedDistance);
  const studentsOptions = useStore(racePageModel.$studentsOptions);
  const handleClose = () => racePageModel.modalVisibilityChanged(false);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography>{`Оберіть користувача, який бере участь у ${activeRace?.name}`}</Typography>
        </DialogContentText>

        {activeRace && activeRace.distances?.length > 1 && (
          <FormControl sx={{ marginTop: "16px" }} fullWidth>
            <InputLabel id="distance-for-race-label">
              Choose distance
            </InputLabel>

            <Select
              id="user-for-race"
              sx={{ width: "100%" }}
              label="Choose distance"
              labelId="distance-for-race-label"
              value={choosedDistance || ""}
              onChange={(e) =>
                racePageModel.distanceChoosed(e.target.value as DistancesString)
              }
            >
              {activeRace.distances.map((distance) => (
                <MenuItem value={distance} key={distance}>
                  {DISTANCES[distance as DistancesString]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        <FormControl sx={{ marginTop: "16px" }} fullWidth>
          <InputLabel id="user-for-race-label">Choose User</InputLabel>
          <Select
            sx={{ width: "100%" }}
            labelId="user-for-race-label"
            label="Choose User"
            value={choosedStudent || ""}
            onChange={(e) => racePageModel.userChoosed(+e.target.value)}
          >
            {!!studentsOptions.length &&
              studentsOptions.map((stud) => (
                <MenuItem value={stud.id} key={stud.id}>
                  {stud.name}
                </MenuItem>
              ))}
            {!studentsOptions.length && "Студентів не знайдено"}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={() => racePageModel.userAddInitiated()} autoFocus>
          Add User
        </Button>
      </DialogActions>
    </Dialog>
  );
};
