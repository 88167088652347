import React, { createContext, useState, useMemo } from "react";
import { Roles } from "../shared/API/types";

interface IAuthContext {
  authenticated: boolean;

  logIn: (token: string) => void;
  checkAuth: () => void;
  logOut: () => void;
}

export const getLocalToken = (): string | null => {
  return localStorage.getItem("token");
};

export const AuthContext = createContext<IAuthContext>({
  authenticated: !!localStorage.getItem("token"),
  logIn: () => {},
  checkAuth: () => {},
  logOut: () => {},
});

export const AuthProvider: React.FC = ({ children }) => {
  const [authenticated, setAuthenticated] = useState<boolean>(
    !!localStorage.getItem("token")
  );

  const logIn = (token: string) => {
    localStorage.setItem("token", token);
    setAuthenticated(true);
  };

  const checkAuth = () => {
    const token = getLocalToken();
    setAuthenticated(!!token);
  };

  const logOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    setAuthenticated(false);
  };

  const contextValue = useMemo(
    () => ({
      authenticated,

      logIn,
      checkAuth,
      logOut,
    }),
    [authenticated]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
