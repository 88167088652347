import axios, { AxiosResponse, AxiosRequestConfig, AxiosError } from "axios";
import {
  IUser,
  IActivity,
  IPlanActivity,
  IPlanActivityCreation,
  IWeekRunStatistics,
  ITrainingTemplate,
  TrainingTypes,
  IVdot,
  IUserCreationRequest,
  IRace,
  IRaceCreationRequest,
  AddRaceToUserRequest,
  UserRace,
} from "./types";
import { getLocalToken } from "../../providers/AuthProvider";
import { toast } from "react-toastify";

axios.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getLocalToken();
    if (token) {
      const header = `Bearer ${token}`;
      config.headers = { authorization: header };
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  // if (error.message === 'Network Error' && !error.response) {
  //   toast.error("The server isn't responding...");
  // }

  if (!error.response) {
    throw error;
  }

  if (error.response.status === 401) {
    localStorage.removeItem("token");

    document.location = `/`;
  }

  if (error.response.status === 500) {
    toast.error(" Спробуйте пізніше");
    // setTimeout(() => {
    //   document.location = `/`;
    // }, 1000);
  }

  throw error;
});

//common

export const getToken = async (
  authCode: string
): Promise<AxiosResponse<string>> => {
  return axios.post("/strava/token", { code: authCode });
};

export const getCurrentUser = async () => {
  return axios.get<IUser>("/user");
};

export const getCurrentUserActivities = async (
  fromDate: string,
  toDate: string
) => {
  return axios.get<IActivity[]>("/activities", {
    params: { from: fromDate, to: toDate },
  });
};

export const getCurrentUserPlan = async (fromDate: string, toDate: string) => {
  return axios.get<IPlanActivity[]>("/plan", {
    params: { from: fromDate, to: toDate },
  });
};

//coach lab

export const getAllUsers = async () => {
  return axios.get<IUser[]>("/user/all");
};

export const getUserById = async (id: string) => {
  return axios.get<IUser>(`/user/${id}`);
};

export const getActivitiesById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  return axios.get<IActivity[]>(`/activities/${id}`, {
    params: { from: fromDate, to: toDate },
  });
};

export const getPlanById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  return axios.get<IPlanActivity[]>(`/plan/${id}`, {
    params: { from: fromDate, to: toDate },
  });
};

export const getActivitiesStatisticsById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  return axios.get<IWeekRunStatistics>(`/activities/statistics/${id}`, {
    params: { from: fromDate, to: toDate },
  });
};

export const setUserPlan = async (
  weekPlan: IPlanActivityCreation[] | IPlanActivity[]
) => {
  return axios.post("/plan", weekPlan);
};

export const deleteUserPlan = (id: number) => axios.delete(`/plan/${id}`);

export const syncWithStravaById = async (id: number) => {
  return axios.post(`/strava/sync/${id}`);
};

export const addMonthPayment = async (userId: number) => {
  return axios.post(`/payment/addMonth/${userId}`);
};

export const postUserVdot = async (
  userId: number,
  distance: number,
  time: string,
  date: string
) => {
  return axios.post(`/user/${userId}/vdot`, {
    distance,
    time,
    date,
  });
};

export const postCalculateVdot = async (
  distance: number,
  time: string,
  date: string
) => {
  return axios.post(`/vdot/count`, {
    distance,
    time,
    date,
  });
};

export const postTrainingTemplate = async (params: ITrainingTemplate) => {
  return axios.post("/template", params);
};

export const deleteTrainingTemplate = async (templateId: number) => {
  return axios.delete(`template/${templateId}`);
};

export const getAllTrainingTemplates = async () => {
  return axios.get<ITrainingTemplate[]>("/template/all");
};

export const getTrainingTamplatesWithPersonalTempoByType = (
  type: TrainingTypes,
  userId: number
) => {
  return axios.get<ITrainingTemplate[]>("/template/tempo", {
    params: { type, userId },
  });
};

export const getUserVdotHistory = (id: number) =>
  axios.get<IVdot[]>(`/user/${id}/vdot`);

export const setActiveUserVdot = (id: number) =>
  axios.post<IVdot[]>(`/vdot/setActive/${id}`);

export const deleteUserVdot = (id: number) => axios.delete(`/vdot/${id}`);

//user creation

export const getUserStravaInfoById = (id: string) =>
  axios.get(`/strava/user/${id}`);

export const createUser = (user: IUserCreationRequest) =>
  axios.post("/user", user);

export const getCoaches = () => axios.get("/user/coaches");

// Races

export const getAllRaces = async () => {
  return axios.get<IRace[]>("/race/all");
};

export const createRace = (race: IRaceCreationRequest) =>
  axios.post("/race", race);

export const editRace = (race: Partial<IRaceCreationRequest>, id: number) =>
  axios.put(`/race/${id}`, race);

export const addRaceToUser = (fields: AddRaceToUserRequest) =>
  axios.post("/race/addToRace", fields);

export const getUsersRace = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  return axios.get<UserRace[]>(`/race/user/${id}`, {
    params: { from: fromDate, to: toDate },
  });
};

//ZONES

//This method is used to create/update zones for user. Only for coaches
export const setUserZones = async (
  id: number,
  zones: { [key: string]: string } // z1-z5 values
) => {
  return axios.post(`/user/${id}/zones`, zones);
};

//This method is used to get zones for user. Only for coaches
export const getUserZones = async (id: number) => {
  return axios.get(`/user/${id}/zones`);
};
