export type TokenResponseType = {
  data: string;
};

export interface IActivity {
  name: string;
  distance: number;
  time: string;
  pace: string;
  stravaId: number;
  type: string;
  training_id: number;
  start_date_local: string;
  z1: number;
  z2: number;
  z3: number;
  z4: number;
  z5: number;
}

export type TrainingTypes =
  | "REST"
  | "INTERVAL"
  | "EASY"
  | "LONG"
  | "COMPETITION"
  | "TEMPO";

export enum TrainingTypesEnum {
  REST,
  INTERVAL,
  EASY,
  LONG,
  TEMPO,
  COMPETITION,
}
export interface IPlanActivityCreation {
  text: string;
  date: string;
  trainingType: TrainingTypes;
  userId: number;
}

export interface IPlanActivity extends IPlanActivityCreation {
  id: number;
}

export type Roles = "ADMIN" | "USER" | "COACH";

export interface IUser {
  id: number;
  stravaId: number;
  name: string;
  surname: string;
  profile?: string;
  roles: Roles[];
  activities?: IActivity[];
  price: number;
  paymentTo: string;
  coach?: string;
  vdot: number;
  hasPlanForTomorrow: boolean;
  telegramName: string;
  isBotConnected: boolean;
  isStravaConnected: boolean;
  telegramCode: number;
  futureRaces: UserRace[];
  pastRaces: UserRace[];
  closestRace: UserRace | null;
}

export type ZonesList = "z1" | "z2" | "z3" | "z4" | "z5";
export interface IWeekRunStatistics {
  hr: number;
  kilometers: number;
  runTime: string;
  otherTime: string;
  pace: string;
  z1: number;
  z2: number;
  z3: number;
  z4: number;
  z5: number;
}

export interface ITrainingTemplate {
  id?: number;
  text: string;
  coachId: number;
  trainingType: TrainingTypes;
}

export interface IVdot {
  id: number;
  date: string;
  createdDate: string;
  user?: IUser;
  vdot: number;
  tempoFive: string;
  tempoTen: string;
  tempoHalf: string;
  tempoFull: string;
  tempoEasyFrom: string;
  tempoEasyTo: string;
  tempoThreshold: string;
  tempoInterval: string;
  tempoRepetition: string;
  isActive: boolean;
}

export interface IUserCreationRequest {
  name?: string;
  surname?: string;
  stravaId: number | null;
  price: number;
  lastPayment?: string | null; //yyyy-mm-dd
  isCoach: boolean;
  telegramName?: string;
  coachId: number;
}

export enum DISTANCES {
  FIVE = "5km",
  TEN = "10km",
  HALFMARATHON = "21.1km",
  MARATHON = "42.2km",
}

export type DistancesString = keyof typeof DISTANCES;

export interface IRace {
  id: number;
  name: string;
  date: string;
  resultsLink: string;
  distances: DistancesString[];
  country: string;
  city: string;
  createdId: number;
}

export interface UserRace {
  raceName: string;
  raceDate: string;
  distance: string;
  country: string;
  city: string;
}

export interface IRaceCreationRequest {
  name: string;
  date: string | null; //DD.MM.YYYY
  resultsLink: string;
  distances: DistancesString[];
  country: string;
  city: string;
}

export interface AddRaceToUserRequest {
  userId: number;
  raceId: number;
  distances: DistancesString[];
}

export type Zone = { [key: string]: string };
