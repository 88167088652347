import React from "react";
import { TableHead, TableRow, TableCell } from "@mui/material";

const headers = [
  "Name",
  "Date",
  "City",
  "Country",
  "Distances",
  "Results",
  "Actions",
];

export const RaceTableHead: React.FC = ({}) => {
  const tableCells = headers.map((str) => {
    return <TableCell key={str}>{str}</TableCell>;
  });

  return (
    <TableHead>
      <TableRow>{tableCells}</TableRow>
    </TableHead>
  );
};
