import { createEvent, restore, sample, createStore, attach } from "effector";
import { createGate } from "effector-react";
import { raceModel } from "entities/race";
import { studentsModel } from "entities/students";
import { viewerModel } from "entities/viewer";
import { reset } from "patronum";
import { IRace, DistancesString } from "shared/API/types";

export const Gate = createGate();
export const AddUserModalGate = createGate();

export const getStudentsListFx = attach({
  effect: studentsModel.getAllStudentsFx,
});

export const modalVisibilityChanged = createEvent<boolean>();
export const raceChoosed = createEvent<IRace>();
export const userChoosed = createEvent<number>();
export const distanceChoosed = createEvent<DistancesString>();
export const choosedInfoReset = createEvent();
export const userAddInitiated = createEvent();

export const $races = restore(raceModel.getAllRacesFx.doneData, []);
export const $isAddUserModalActive = restore(modalVisibilityChanged, false);

export const $studentsOptions = createStore<{ id: number; name: string }[]>([]);

export const $choosedRace = restore(raceChoosed, null);
export const $choosedUser = restore(userChoosed, null);
export const $choosedDistance = restore(distanceChoosed, null);
export const $addUserError = createStore("");

sample({
  clock: Gate.open,
  target: raceModel.getAllRacesFx,
});

sample({
  clock: [Gate.open, viewerModel.$hasAuthorities],
  source: { isOpen: Gate.status, hasAuth: viewerModel.$hasAuthorities },
  filter: ({ isOpen, hasAuth }) => isOpen && hasAuth,
  target: getStudentsListFx,
});

sample({
  clock: getStudentsListFx.doneData,
  fn: (res) =>
    res.map((stud) => ({ id: stud.id, name: `${stud.name} ${stud.surname}` })),
  target: $studentsOptions,
});

sample({
  clock: raceChoosed,
  filter: ({ distances }) => distances.length === 1,
  fn: ({ distances }) => distances[0],
  target: distanceChoosed,
});

sample({
  clock: userAddInitiated,
  source: {
    userId: $choosedUser,
    race: $choosedRace,
    distance: $choosedDistance,
  },
  filter: ({ userId, race, distance }) => {
    return !!userId && !!race && !!distance;
  },
  fn: ({ userId, race, distance }) => ({
    userId: userId!,
    raceId: race?.id!,
    distances: [distance!],
  }),
  target: raceModel.addRaceToUserFx,
});

sample({
  clock: raceModel.addRaceToUserFx.doneData,
  fn: () => false,
  target: modalVisibilityChanged,
});

sample({
  clock: raceModel.addRaceToUserFx.doneData,
  target: viewerModel.getViewerFx,
});

sample({
  clock: raceModel.addRaceToUserFx.failData,
  fn: () =>
    "Щось пішло не так :( Перезавантажте, будь ласка, сторінку та спробуйте ще раз ",
  target: $addUserError,
});

sample({
  clock: modalVisibilityChanged,
  filter: (value) => !value,
  target: choosedInfoReset,
});

reset({
  clock: choosedInfoReset,
  target: [$choosedRace, $choosedUser, $choosedDistance, $addUserError],
});
