import { createContext, useState } from "react";
import { IUser, IActivity } from "../shared/API/types";

interface IUserContext {
  currentUser: IUser | undefined;
  setUser: (args: IUser) => void;
  removeUser: () => void;
  updateActivities?: (args: IActivity[]) => void;
}

export const UserContext = createContext<IUserContext>({
  currentUser: undefined,
  setUser: () => {},
  removeUser: () => {},
});

export const UserProvider: React.FC = ({ children }) => {
  const [currentUser, setUser] = useState<IUser | undefined>(undefined);

  const removeUser = () => {
    setUser(undefined);
  };

  const updateActivities = (act: IActivity[]) => {
    setUser({ ...currentUser!, activities: act });
  };

  return (
    <UserContext.Provider
      value={{ currentUser, setUser, removeUser, updateActivities }}
    >
      {children}
    </UserContext.Provider>
  );
};
