import {
  AccessTime,
  CalendarMonthOutlined,
  LocationOnOutlined,
  RouteOutlined,
} from "@mui/icons-material";
import { Box, Card, CardContent, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { DISTANCES, UserRace, DistancesString } from "shared/API/types";
import {
  dateToShowFormat,
  getDayJsFromReverseString,
} from "shared/helpers/dates";

interface NextRaceCarsProps {
  race: UserRace;
}
export const NextRaceCard = ({ race }: NextRaceCarsProps) => {
  const theme = useTheme();
  return (
    <Box
      component={Card}
      display={"flex"}
      flexDirection={{ xs: "column", md: "column" }}
      justifyContent={"space-between"}
      variant={"elevation"}
      sx={{
        transition: "all .2s ease-in-out",
        "&:hover": {
          transform: `translateY(-${theme.spacing(1 / 2)})`,
        },
        height: "100%",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          gap: "4px",
        }}
      >
        <Box>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography
              variant={"h5"}
              color={"secondary.main"}
              textTransform={"uppercase"}
              fontSize={"1rem"}
            >
              Next Race
            </Typography>
            <Box display={"flex"} alignItems={"center"}>
              {/* {" "}
              <AccessTime
                fontSize={"small"}
                sx={{ height: "16px", marginTop: "-5px" }}
              />{" "} */}
              <Typography
                variant={"h5"}
                textTransform={"uppercase"}
                fontSize={"1rem"}
              >
                {dayjs().to(getDayJsFromReverseString(race.raceDate), true)}
              </Typography>
            </Box>

            {/* <Typography variant={"subtitle1"} color={"secondary.main"}>
              {dayjs(race.raceDate).format("MMMM, YYYY")}
            </Typography> */}
          </Box>
        </Box>
        <Box
          marginTop={1}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          height={"100%"}
          gap={1}
        >
          <Typography variant={"body1"} gutterBottom>
            <Box fontWeight={600}>{race.raceName}</Box>
          </Typography>
          <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
            <Box display={"flex"} alignItems={"center"}>
              <LocationOnOutlined fontSize="small" sx={{ height: "16px" }} />
              <Typography variant={"body2"}>
                {race.city + ", " + race.country}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <CalendarMonthOutlined fontSize="small" sx={{ height: "16px" }} />
              <Typography variant={"body2"}>
                {getDayJsFromReverseString(race.raceDate).format(
                  dateToShowFormat
                )}
              </Typography>
            </Box>
            <Box display={"flex"} alignItems={"center"}>
              <RouteOutlined fontSize="small" sx={{ height: "16px" }} />
              <Typography
                variant={"body2"}
                fontWeight={"bold"}
                fontStyle={"italic"}
              >
                {DISTANCES[race.distance as DistancesString]}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Box>
  );
};
