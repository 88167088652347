import { Autocomplete, Box, TextField, TextFieldProps } from "@mui/material";

import { ChangeEvent, SyntheticEvent } from "react";
import { CountryType, countries, countryLabels } from "./countries";

interface CountrySelectProps {
  value: string;
  handleFormChange: (
    event: SyntheticEvent,
    value: CountryType | null,
    reason: string
  ) => void;
}
export const CountrySelect = ({
  value,
  handleFormChange,
  ...props
}: CountrySelectProps & TextFieldProps) => {
  return (
    // <TextField
    //   name="country"
    //   label="country"
    //   type="text"
    //   onChange={handleFormChange}
    //   value={value}
    //   fullWidth
    //   required
    // />
    <Autocomplete
      options={countryLabels}
      value={value}
      autoHighlight
      onChange={handleFormChange}
      getOptionLabel={(option) => option}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          {option}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          name="country"
          type="text"
          required
          value={value}
          {...params}
          {...props}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};
