import { createStore, sample } from "effector";
import { viewerModel } from "entities/viewer";
import { reset } from "patronum";

export const $getViewerError = createStore<string | null>(null);

sample({
  clock: viewerModel.getViewerFx.fail,
  fn: () => "Sorry, something went wrong",
  target: $getViewerError,
});

reset({
  clock: viewerModel.getViewerFx,
  target: $getViewerError,
});
