import { toast } from "react-toastify";
import {
  getAllUsers,
  getToken,
  getCurrentUser,
  getCurrentUserActivities,
  getActivitiesById,
  getCurrentUserPlan,
  getPlanById,
  setUserPlan,
  syncWithStravaById,
  getActivitiesStatisticsById,
  addMonthPayment,
  postUserVdot,
  postTrainingTemplate,
  getAllTrainingTemplates,
  deleteTrainingTemplate,
  getTrainingTamplatesWithPersonalTempoByType,
  postCalculateVdot,
  getUserVdotHistory,
  setActiveUserVdot,
  deleteUserVdot,
  deleteUserPlan,
  getUserStravaInfoById,
  createUser,
  getAllRaces,
  createRace,
  getUsersRace,
  editRace,
  setUserZones,
  getUserZones,
} from "./api";
import {
  IUser,
  IPlanActivityCreation,
  ITrainingTemplate,
  TrainingTypes,
  IPlanActivity,
  IUserCreationRequest,
  IRace,
  IRaceCreationRequest,
  Zone,
} from "./types";

//COMMON

export const fetchToken = async (authCode: string) => {
  const response = await getToken(authCode);
  const token = response.data;
  return token;
};

export const fetchCurrentUser = async () => {
  const { data } = await getCurrentUser();
  const user: IUser = data;
  return user;
};

export const fetchCurrentUserActivities = async (
  fromDate: string,
  toDate: string
) => {
  const { data } = await getCurrentUserActivities(fromDate, toDate);
  const activities = data;
  return activities;
};

export const fetchCurrentUserPlan = async (
  fromDate: string,
  toDate: string
) => {
  const { data } = await getCurrentUserPlan(fromDate, toDate);
  const plan = data;
  return plan;
};

//COACH LAB

export const fetchAllUsers = async () => {
  const response = await getAllUsers();
  const users: IUser[] = response.data;
  return users;
};

export const fetchActivitiesById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  const { data } = await getActivitiesById(id, fromDate, toDate);
  const activities = data;
  return activities;
};

export const fetchPlanById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  const { data } = await getPlanById(id, fromDate, toDate);
  const plan = data;
  return plan;
};

export const fetchActivitiesStatisticsById = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  const { data } = await getActivitiesStatisticsById(id, fromDate, toDate);
  const statistics = data;
  return statistics;
};

export const createPlan = async (
  obj: IPlanActivityCreation[] | IPlanActivity[]
) => {
  const { status } = await toast.promise(setUserPlan(obj), {
    pending: "Зачекайте",
    success: "План успішно додано 👌",
    error: "Йой... Шось пішло не так 🤯",
  });
  return status;
};

export const deletePlan = async (id: number) => {
  const { status } = await toast.promise(deleteUserPlan(id), {
    pending: "Зачекайте",
    success: "План видалено 👌",
    error: "Йой... Шось пішло не так 🤯",
  });
  return status;
};

export const fetchStravaSync = async (userId: number) => {
  const { status } = await syncWithStravaById(userId);
  return status;
};

export const fetchPaymentProlongation = async (userId: number) => {
  const { status } = await addMonthPayment(userId);
  return status;
};

export const calculateVdot = async (
  distance: number,
  time: string,
  date: string
) => {
  const { data } = await postCalculateVdot(distance, time, date);
  return data;
};

export const setUserVdot = async (
  userId: number,
  distance: number,
  time: string,
  date: string
) => {
  const { data } = await postUserVdot(userId, distance, time, date);
  return data;
};

export const fetchUserVdotHistory = async (id: number) => {
  const { data } = await getUserVdotHistory(id);
  return data;
};

export const setNewActiveVdot = async (id: number) => {
  const { data } = await toast.promise(setActiveUserVdot(id), {
    pending: "Зачекайте",
    success: " Активний VDOT збережено 👌",
    error: "Йой... Шось пішло не так 🤯",
  });
  return data;
};

export const deleteVdot = async (id: number) => {
  const { status } = await toast.promise(deleteUserVdot(id), {
    pending: "Зачекайте",
    success: "Показник VDOT видалено 👌",
    error: "Йой... Шось пішло не так 🤯",
  });
  return status;
};

export const setTrainingTemplate = async (params: ITrainingTemplate) => {
  const { data } = await toast.promise(postTrainingTemplate(params), {
    pending: "Зачекайте",
    success: "Шаблон збережено 👌",
    error: "Упс... Шось пішло не так 🤯",
  });

  return data;
};

export const fetchAllTrainingTemplates = async () => {
  const { data } = await getAllTrainingTemplates();
  return data;
};

export const removeTrainingTemplate = async (templateId: number) => {
  const { status } = await toast.promise(deleteTrainingTemplate(templateId), {
    pending: "Зачекайте",
    success: "Шаблон видалено 👌",
    error: "Упс... Шось пішло не так 🤯",
  });
  return status;
};

export const getPersonalTemplateByType = async (
  trainingType: TrainingTypes,
  userId: number
) => {
  const { data } = await getTrainingTamplatesWithPersonalTempoByType(
    trainingType,
    userId
  );
  return data;
};

//user creation

export const checkStravaName = async (id: string) => {
  const { data } = await toast.promise(getUserStravaInfoById(id), {
    error: `Упс... Шось пішло не так 🤯.`,
  });
  return data;
};

export const addNewStudent = async (form: IUserCreationRequest) => {
  const { status } = await toast.promise(createUser(form), {
    pending: "Зачекайте",
    error: "Упс... Шось пішло не так 🤯",
  });
  return status;
};

// Races

export const fetchAllRaces = async () => {
  const response = await getAllRaces();
  const races: IRace[] = response.data;
  return races;
};

export const addNewRace = async (form: IRaceCreationRequest) => {
  const { status } = await toast.promise(createRace(form), {
    pending: "Зачекайте",
    error: "Упс... Шось пішло не так 🤯",
  });
  return status;
};

export const getStudentRaces = async (
  id: number,
  fromDate: string,
  toDate: string
) => {
  try {
    const { data } = await getUsersRace(id, fromDate, toDate);
    const plan = data;
    return plan;
  } catch (e) {
    console.log(e);
  }
};

export const editExistingRace = async (
  form: Partial<IRaceCreationRequest>,
  id: number
) => {
  const { status } = await toast.promise(editRace(form, id), {
    pending: "Зачекайте",
    error: "Упс... Шось пішло не так 🤯",
  });
  return status;
};

//ZONES

export const setStudentZones = async (
  id: number,
  zones: { [key: string]: string }
) => {
  const { data } = await toast.promise(setUserZones(id, zones), {
    pending: "Зачекайте",
    success: "Пульсові зони оновлено👌",
    error: "Упс... Шось пішло не так 🤯",
  });
  return data;
};

export const getStudentZones = async (id: number) => {
  const response = await getUserZones(id);
  const zones: Zone = response.data;
  return zones;
};
