import { createTheme } from "@mui/material";
import { PALETTE as palette } from "./palette";
import { TYPOGRAPHY as typography } from "./typography";

export const MAIN_THEME = createTheme({
  palette,
  typography,
  components: {
    MuiButton: {
      defaultProps: {
        color: "secondary",
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});
