import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer, Slide } from "react-toastify";
import { ThemeProvider } from "@mui/material";
import { Header } from "./widgets/Header/Header";
import { MAIN_THEME } from "./shared/styles";
import { CssBaseline, CircularProgress } from "@mui/material";
import "react-toastify/dist/ReactToastify.min.css";
import { RenderRoutes } from "./pages/navigation/Router";
import { ROUTER_CONFIG } from "./pages/navigation/router-config";
import { AuthProvider } from "./providers/AuthProvider";
import { UserProvider } from "./providers/UserProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/uk";
import "./App.css";

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.locale("uk");

const App: React.FC = () => {
  return (
    <>
      <ThemeProvider theme={MAIN_THEME}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <ToastContainer
            position="bottom-right"
            limit={3}
            draggable={false}
            transition={Slide}
            hideProgressBar
            closeOnClick={false}
            autoClose={4000}
            style={{ width: "auto" }}
            theme="colored"
          />
          <CssBaseline />
          <Router>
            <AuthProvider>
              <UserProvider>
                <Header />
                <Suspense
                  fallback={
                    <div>
                      <CircularProgress />
                    </div>
                  }
                >
                  <React.StrictMode>
                    <RenderRoutes routes={ROUTER_CONFIG} />
                  </React.StrictMode>
                </Suspense>
              </UserProvider>
            </AuthProvider>
          </Router>
        </LocalizationProvider>
      </ThemeProvider>
    </>
  );
};

export default App;
