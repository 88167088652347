import React from "react";
import { Container, Typography, Paper, Box } from "@mui/material";
import { useStyles } from "./StartPage.styles";
import poweredByStravaImg from "./../../shared/assets/api_logo_pwrdBy_strava_stack_white.svg";
const StartPage: React.FC = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.background}>
      <Container className={classes.container} sx={{ position: "relative" }}>
        <Typography variant="h1" className={classes.text}>
          Run this world with low pulse
        </Typography>
        <Box sx={{ position: "absolute", bottom: 2, right: 2, width: "100px" }}>
          <img src={poweredByStravaImg} alt="Powered By Strava logo" />
        </Box>
      </Container>
    </Paper>
  );
};

export default StartPage;
