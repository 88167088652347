import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    height: 72,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 160,
  },
  avatar: {
    width: 56,
    height: 56,
  },
  menu: {
    "& .MuiTypography-button": {
      color: theme.palette.primary.main,
      textDecoration: "none",
    },

    "& a": {
      textDecoration: "none",
    },
  },
}));
