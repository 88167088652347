import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/material";

export const useStyles = makeStyles(
  (theme: Theme) => ({
    page: {
      margin: theme.spacing(9, "auto"),
    },
  }),
  { name: "Page" }
);
