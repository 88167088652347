import { PaletteOptions } from "@mui/material";
import { deepOrange, cyan, red, green } from "@mui/material/colors";

export const PALETTE: PaletteOptions = {
  primary: {
    main: "#000000",
    light: "#bdbdbd",
    contrastText: "#ffffff",
  },
  secondary: {
    main: deepOrange.A400,
    dark: "#FC5200",
  },
  info: {
    main: cyan[500],
    // dark: "#36D186",
  },
  error: {
    main: red[600],
  },
  success: {
    main: green[500],
  },
  // warning: {
  //   main: "#EB5757",
  //   contrastText: "#fff",
  // },
  // text: {
  //   primary: "#000",
  //   secondary: "#767676",
  // },
  // background: {
  //   paper: "#fff",
  //   default: "#E5E5E5",
  // },
  // action: {
  //   active: "#fff",
  //   hover: "#83E1FF",
  // },
  // divider: "#000",
};
