import { makeStyles } from "@mui/styles";
import startImgRunning from "shared/assets/startImgRunning.jpg";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  background: {
    width: "100%",
    minHeight: `calc(100vh - ${theme.spacing(9)})`,
    backgroundImage: `url(${startImgRunning})`,
    backgroundSize: "cover",
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    position: "relative",
    marginTop: theme.spacing(9),
  },

  page: {
    padding: 8,
    "& .MuiButton-root": {
      variant: "contained",
    },
  },

  table: {
    margin: theme.spacing(4, "auto"),
  },

  tableLabel: {
    color: theme.palette.primary.contrastText,
  },

  tableRow: {
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },

  tableRowDisabled: {
    textDecoration: "none",
    backgroundColor: theme.palette.action.disabledBackground,
  },

  avatar: {
    height: 16,
    width: 16,
  },
}));
