import React from "react";
import { Button, IconButton } from "@mui/material";
import stravaAuthButton from "../../assets/btn_strava_connectwith_orange.svg";

export const STRAVA_CODE = process.env.REACT_APP_STRAVA_CODE as string;

const getStravaCode = () => {
  let baseUrl = document.location.origin;
  document.location = `https://www.strava.com/api/v3/oauth/authorize?client_id=${STRAVA_CODE}&redirect_uri=${baseUrl}/redirect&scope=activity:read_all,profile:read_all,read&response_type=code`;
};

export const AuthButton: React.FC = () => {
  const handleSignIn = () => getStravaCode();
  return (
    <IconButton onClick={handleSignIn}>
      <img src={stravaAuthButton} alt="cup" />
    </IconButton>
  );
};
