import { TypographyOptions } from "@mui/material/styles/createTypography";

export const TYPOGRAPHY: TypographyOptions = {
  htmlFontSize: 16,
  fontFamily: [
    "EurostileExtended",
    "EurostileExtendedBlack",
    "Helvetica",
    "Roboto",
    "sans-serif",
  ].join(","),
  h1: {
    fontFamily: "EurostileExtendedBlack",
    fontWeight: 900,
    fontSize: "48px",
    lineHeight: "48px",
    textTransform: "uppercase",
    "@media (max-width:600px)": {
      fontSize: "36px",
      lineHeight: "36px",
    },
  },
  h2: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "3.75rem",
    lineHeight: 1.2,
    letterSpacing: "-0.00833em",
  },
  h3: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "3rem",
    lineHeight: 1.167,
    letterSpacing: "0em",
  },
  h4: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "2.125rem",
    lineHeight: 1.235,
    letterSpacing: "0.00735em",
  },
  h5: {
    fontFamily: "EurostileExtended",
    fontWeight: 400,
    fontSize: "1.5rem",
    lineHeight: 1.334,
    letterSpacing: "0em",
  },
  h6: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: 1.6,
    letterSpacing: "0.0075em",
  },
  subtitle1: {
    fontFamily: "EurostileExtended",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    textTransform: "uppercase",
  },
  subtitle2: {
    fontFamily: "EurostileExtended",
    fontWeight: 500,
    fontSize: "0.675rem",
    lineHeight: 1.57,
    letterSpacing: "0.00714em",
  },
  body1: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  body2: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "0.875rem",
    lineHeight: 1.2,
    letterSpacing: "0.01071em",
  },
  button: {
    fontFamily: "Roboto",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: 1.75,
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
  },
  caption: {
    fontFamily: "Roboto",
    fontWeight: 300,
    fontSize: "0.75rem",
    lineHeight: 1.66,
    letterSpacing: "0.03333em",
  },
  overline: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "0.75rem",
    lineHeight: 2.66,
    letterSpacing: "0.08333em",
    textTransform: "uppercase",
  },
};
