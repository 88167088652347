import { makeStyles } from "@mui/styles";
import startImgRunning from "../../shared//assets/startImgRunning.jpg";
import { Theme } from "@mui/material";

export const useStyles = makeStyles((theme: Theme) => ({
  background: {
    minHeight: "100vh",
    backgroundImage: `url(${startImgRunning})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    "& .MuiContainer-root": {
      display: "flex",
      position: "relative",
      height: "100vh",
      justifyContent: "center",
      flexDirection: "column",
    },
  },

  text: {
    margin: "auto",
    color: "#ffffff",
    textTransform: "uppercase",
    [theme.breakpoints.up("md")]: {
      width: "900px",
    },
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      width: "auto",
    },

    backgroundColor: "rgba(0, 0, 0, 0.486)",
  },

  container: {},
}));
