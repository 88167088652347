import {
  Avatar,
  IconButton,
  Tooltip,
  MenuItem,
  Box,
  Typography,
  Menu,
} from "@mui/material";
import React, { useState, useCallback, useContext } from "react";
import { Link } from "react-router-dom";
import { useStyles } from "./Header.style";
import { Roles } from "shared/API/types";
import { AuthContext } from "../../providers/AuthProvider";

export interface IAccountMenu {
  avatar?: string;
  role?: Roles[];
}

export const AccountMenu: React.FC<IAccountMenu> = ({ avatar, role }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { logOut } = useContext(AuthContext);
  const classes = useStyles();

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleToggle = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleLogOut = useCallback(() => {
    handleClose();
    logOut();
  }, [handleClose, logOut]);

  return (
    <Box>
      <Tooltip title="Open Settings">
        <IconButton size="large" onClick={handleToggle}>
          <Avatar alt="avatar" src={avatar} className={classes.avatar} />
        </IconButton>
      </Tooltip>
      <Menu
        open={!!anchorEl}
        id="account-menu"
        anchorEl={anchorEl}
        keepMounted
        onClose={handleClose}
        elevation={3}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        className={classes.menu}
      >
        <Link to="/profile">
          <MenuItem onClick={handleClose}>
            <Typography variant="button" textAlign="center">
              Profile
            </Typography>
          </MenuItem>
        </Link>
        {(role?.includes("COACH") || role?.includes("ADMIN")) && (
          <Link to="/list">
            <MenuItem onClick={handleClose}>
              <Typography variant="button" textAlign="center">
                Runners List
              </Typography>
            </MenuItem>
          </Link>
        )}
        {(role?.includes("COACH") || role?.includes("ADMIN")) && (
          <Link to="/templates">
            <MenuItem onClick={handleClose}>
              <Typography variant="button" textAlign="center">
                Training Templates
              </Typography>
            </MenuItem>
          </Link>
        )}
        {(role?.includes("COACH") || role?.includes("ADMIN")) && (
          <Link to="/race">
            <MenuItem onClick={handleClose}>
              <Typography variant="button" textAlign="center">
                Races
              </Typography>
            </MenuItem>
          </Link>
        )}
        <Link to="/">
          <MenuItem onClick={handleLogOut}>
            <Typography variant="button" textAlign="center">
              Log Out
            </Typography>
          </MenuItem>
        </Link>
      </Menu>
    </Box>
  );
};
