import { createEffect, createEvent, restore, sample } from "effector";
import { raceModel } from "entities/race";
import { viewerModel } from "entities/viewer";
import { reset } from "patronum";

import { editExistingRace } from "shared/API/apiUtilities";
import { IRace } from "shared/API/types";

export const editRaceInfoFx = createEffect(
  async ({ form, id }: { form: Partial<IRace>; id: number }) => {
    let result = await editExistingRace(form, id);
    return result;
  }
);

export const modalVisibilityChanged = createEvent<boolean>();
export const raceEditRequested = createEvent<IRace>();
export const raceEditReset = createEvent();
export const editRaceSubmitted = createEvent<Partial<IRace>>();

export const $isOpen = restore(modalVisibilityChanged, false);

export const $raceToEdit = restore(raceEditRequested, null);

sample({
  clock: editRaceSubmitted,
  fn: (race) => ({ form: { ...race }, id: race.id || 0 }),
  target: editRaceInfoFx,
});

sample({
  clock: editRaceInfoFx.doneData,
  target: [raceModel.getAllRacesFx, viewerModel.getViewerFx],
});

reset({
  clock: raceEditReset,
  target: $raceToEdit,
});
