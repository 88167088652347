import { createEffect } from "effector";
import { toast } from "react-toastify";
import { addRaceToUser, createRace, getAllRaces } from "../../shared/API/api";
import {
  AddRaceToUserRequest,
  IRace,
  IRaceCreationRequest,
} from "../../shared/API/types";

export const getAllRacesFx = createEffect(async () => {
  const response = await getAllRaces();
  const races: IRace[] = response.data;
  return races;
});

export const addNewRaceFx = createEffect(async (form: IRaceCreationRequest) => {
  const { status } = await toast.promise(createRace(form), {
    pending: "Зачекайте",
    error: "Упс... Шось пішло не так 🤯",
  });
  return status;
});

export const addRaceToUserFx = createEffect(
  async (fields: AddRaceToUserRequest) => {
    const { status } = await toast.promise(addRaceToUser(fields), {
      pending: "Зачекайте",
      error: "Упс... Шось пішло не так 🤯",
    });
    return status;
  }
);
