import React, { useContext } from "react";
import {
  TableBody,
  TableRow,
  TableCell,
  Link as MuiLink,
  IconButton,
} from "@mui/material";
import { Add, Edit, EditOutlined, Remove } from "@mui/icons-material";

import { IRace, DISTANCES } from "shared/API/types";
import { racePageModel } from "..";
import { UserContext } from "providers/UserProvider";
import { editRaceModel } from "features/edit-race";

export interface IRaceTableBodyProps {
  races: Array<IRace>;
  isAdmin: boolean;
  isCoach: boolean;
}

export const RaceTableBody: React.FC<IRaceTableBodyProps> = ({
  races,
  isAdmin,
}) => {
  const { currentUser } = useContext(UserContext);
  const isValidURL = (str: string) => {
    var res = str.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
    );
    return res !== null;
  };

  const openAddUserModal = (race: IRace) => {
    racePageModel.raceChoosed(race);
    racePageModel.modalVisibilityChanged(true);
  };

  const openEditRaceModal = (race: IRace) => {
    editRaceModel.raceEditRequested(race);
    editRaceModel.modalVisibilityChanged(true);
  };
  const rows = races.map((race) => {
    const { id, name, date, resultsLink, distances, country, city } = race;

    return (
      <TableRow hover key={id}>
        <TableCell>{name}</TableCell>
        <TableCell>{date}</TableCell>

        <TableCell>{city}</TableCell>
        <TableCell>{country}</TableCell>
        <TableCell>
          {distances.length ? (
            distances.map((distance) => DISTANCES[distance]).join(", ")
          ) : (
            <Remove color="error" />
          )}
        </TableCell>
        <TableCell>
          {isValidURL(resultsLink) ? (
            <MuiLink
              href={resultsLink}
              target="_blank"
              rel="noreferrer noopener"
            >
              Link to the race
            </MuiLink>
          ) : (
            <Remove color="error" />
          )}
        </TableCell>
        <TableCell>
          {" "}
          <IconButton
            size="small"
            edge="end"
            onClick={() => openAddUserModal(race)}
          >
            <Add color="secondary" />
          </IconButton>
          {(race.createdId === currentUser?.id || isAdmin) && (
            <IconButton
              size="small"
              edge="end"
              onClick={() => openEditRaceModal(race)}
            >
              <EditOutlined color="secondary" />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <TableBody>{rows}</TableBody>
    </>
  );
};
