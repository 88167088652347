import React, { useContext } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { IRouteConfig } from "./types";
import { Page } from "../Page/Page";
import StartPage from "../StartPage/startPage";
import { AuthContext } from "../../providers/AuthProvider";
import { StyledCircularProgress } from "../../shared/components/shared/StyledCircularProgress";

const PrivateRoute: React.FC<IRouteConfig> = ({
  key,
  path,
  exact,
  component,
}) => {
  const { authenticated } = useContext(AuthContext);

  if (authenticated === undefined) return <StyledCircularProgress />;

  if (!authenticated) {
    return <Redirect to="/error_404" />;
  }

  return (
    <Route path={path} key={key} exact={exact} component={component}></Route>
  );
};

export const RenderRoutes: React.FC<{ routes: IRouteConfig[] }> = ({
  routes,
}) => (
  <>
    <Switch>
      <Route path="/" exact component={StartPage} />
      {routes.map((route: IRouteConfig) => {
        if (route.private) {
          return (
            <PrivateRoute
              key={route.key}
              path={route.path}
              exact={route.exact}
              component={route.component}
            />
          );
        }
        return (
          <Route key={route.key} path={route.path} exact={route.exact}>
            <Page component={route.component} />
          </Route>
        );
      })}
    </Switch>
  </>
);
