import {
  createStore,
  createEffect,
  restore,
  sample,
  createEvent,
} from "effector";
import { or, spread } from "patronum";
import { getCurrentUser } from "shared/API/api";
import { IUser } from "shared/API/types";

export const getViewerInitiated = createEvent();

export const getViewerFx = createEffect(async () => {
  const { data } = await getCurrentUser();
  const user: IUser = data;

  return user;
});

export const $viewer = restore(getViewerFx.doneData, null);
export const $isCoach = createStore(false);
export const $isAdmin = createStore(false);
export const $hasAuthorities = or($isAdmin, $isCoach);

sample({
  clock: $viewer,
  filter: (viewer: IUser | null): viewer is IUser => !!viewer,
  fn: (viewer) => ({
    isCoach: viewer.roles.includes("COACH"),
    isAdmin: viewer.roles.includes("ADMIN"),
  }),
  target: spread({
    targets: {
      isCoach: $isCoach,
      isAdmin: $isAdmin,
    },
  }),
});
