import React from "react";
import { Container } from "@mui/material";
import { useStyles } from "./Page.styles";

export interface IPageProps {
  component: React.ComponentType;
}

export const Page: React.FC<IPageProps> = (props) => {
  const classes = useStyles();
  return (
    <Container className={classes.page}>
      <props.component />
    </Container>
  );
};
